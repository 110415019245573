import React from 'react';

import Layout from '../components/layout';
import Nav from '../components/nav';
import Footer from '../components/footer';
import Slogan from '../components/slogan';
import SEO from '../components/seo';
import ContactForm from '../components/contact-form';

import '../scss/attorno.scss';

const Bedankt = () => (
  <Layout>
    <SEO
      title="Contacteer ons"
      description="Had u toch nog vragen of wil u een offerte ontvangen? Contacteer ons nu vrijblijvend voor een voorstel."
    />

    <Nav />

    <header
      className="intro-block py-3 cover-bg"
      style={{ backgroundImage: 'url(/design/img/header/pizza.jpg)' }}
    >
      <div className="container">
        <Slogan heading="Neem contact met ons op" />
      </div>
    </header>

    <section
      className="pattern-bg pt-8 pb-5"
      style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h3>Uw aanvraag is succesvol verzonden!</h3>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </Layout>
);

export default Bedankt;
